<template>
  <div>
    <b-modal
      id="modal-qr"
      title="Enrollment QR Code"
      centered
      size="sm"
      footer-class="justify-content-center"
    >
      <qrcode-vue
        :value="qrValue"
        :size="200"
        level="H"
        class="text-center"
      ></qrcode-vue>

      <template #modal-footer>
        <b-button variant="success" class="btn-icon" @click="saveQr()">
          <feather-icon icon="DownloadIcon" />
          <span class="align-middle ml-50">Download</span>
        </b-button>
        <b-button variant="primary" class="btn-icon" @click="copyQr()">
          <feather-icon icon="CopyIcon" />
          <span class="align-middle ml-50">Copy</span>
        </b-button>
      </template>
    </b-modal>

    <b-card>
      <b-row>
        <b-col lg="9" md="8">
          <b-form-group class="">
            <b-input-group>
              <b-input-group-prepend is-text>
                <feather-icon icon="SearchIcon" />
              </b-input-group-prepend>

              <b-form-input v-model="searchQuery" placeholder="Search...">
              </b-form-input>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col lg="3" md="4" class="d-flex align-items-start">
          <v-select
            class="flex-grow-1"
            v-model="currentStatus"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="statusOptions"
            label="text"
            :reduce="(val) => val.value"
            :clearable="false"
            placeholder="Select status"
            @input="LoadData()"
          />
          <b-button
            @click="openQr()"
            variant="outline-secondary"
            class="btn-icon p-0 mb-1 ml-50"
            v-b-tooltip.hover.top
            title="Generate QR Code"
          >
            <img src="@/assets/images/icons/qr-code.png" width="34" />
          </b-button>
        </b-col>
      </b-row>

      <b-table
        class="mt-1"
        :items="filters"
        :fields="fields"
        :busy="dataLoading"
        show-empty
        responsive
        hover
        small
      >
        <template #table-busy>
          <div
            class="d-flex justify-content-center mb-3"
            style="margin-top: 50px"
          >
            <b-spinner
              style="width: 3rem; height: 3rem"
              type="grow"
              variant="primary"
              label="Spinning"
            ></b-spinner>
          </div>
        </template>

        <template #cell(index)="data">
          {{ data.index + 1 }}
        </template>

        <template #cell(name)="data">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar
                size="44"
                :src="data.item.st.picture"
                variant="light-primary"
              />
            </template>

            <b-link class="font-weight-bold d-block text-nowrap mb-25">
              <b-badge variant="light-primary">
                {{ data.item.st.name }}
              </b-badge>
            </b-link>
            <small class="text-muted">
              <b-badge variant="light-primary" class="mr-50">
                GR -
                {{
                  ["myskoolhpgs", "myskoolprofectus"].includes(reportDomain)
                    ? data.item.st.grNoLocal
                    : data.item.st.grNo
                }}
              </b-badge>
              <b-badge variant="light-primary">
                F# - {{ data.item.st.family_code }}
              </b-badge>
            </small>
            <!-- <b-link class="font-weight-bold d-block text-nowrap mt-25 mb-25">
              <b-badge variant="light-primary">
                {{
                  ["myskoolhpgs", "myskoolprofectus"].includes(reportDomain)
                    ? "CID: "
                    : "ID: "
                }}

                {{
                  ["myskoolhpgs", "myskoolprofectus"].includes(reportDomain)
                    ? data.item.st.grNo
                    : data.item.st.id
                }}
              </b-badge>
            </b-link> -->
          </b-media>
        </template>

        <template #cell(father_name)="data">
          <b-badge variant="light-primary">
            {{ data.item.st.father_name }}
          </b-badge>
        </template>

        <template #cell(contact_father)="data">
          <b-badge variant="light-primary">
            {{ data.item.st.contact_father }}
          </b-badge>
        </template>
        <template #cell(class_current)="data">
          <b-badge variant="light-primary">
            {{ data.item.st.meta_class }}
          </b-badge>
        </template>
        <template #cell(monthly_fee)="data">
          <b-badge variant="light-primary">
            {{ data.item.st.monthly_fee }}
          </b-badge>
        </template>
        <template #cell(enrollmentDate)="data">
          <b-badge variant="light-primary">
            {{
              new Date(data.item.us.enrollmentDate).toLocaleDateString(
                "en-UK",
                {
                  year: "numeric",
                  day: "numeric",
                  month: "short",
                }
              )
            }}
          </b-badge>
        </template>
        <template #cell(status)="data">
          <b-badge
            :variant="
              data.item.us.status == 'pending'
                ? 'light-danger'
                : 'light-success'
            "
          >
            {{ data.item.us.status }}
          </b-badge>
        </template>

        <template #head(actions)="data">
          <div class="text-center">
            <span>{{ data.label }}</span>
          </div>
        </template>

        <template #cell(actions)="data">
          <div class="d-flex">
            <template>
              <b-button
                variant="primary"
                class="btn-icon"
                v-b-tooltip.hover.bottomright
                title="Print"
                @click="openReport(data.item.us, data.item.st)"
              >
                <feather-icon icon="PrinterIcon" />
              </b-button>
              <!-- <b-button
                variant="outline-danger"
                class="btn-icon"
                v-b-tooltip.hover.top
                title="Reject"
              >
                <feather-icon icon="XIcon" />
              </b-button> -->
            </template>
            <!-- <b-button
              v-else
              variant="primary"
              class="btn-icon"
              v-b-tooltip.hover.top
              title="View"
              @click="openDetails(data.item.us, data.item.st)"
            >
              <feather-icon icon="EyeIcon" />
            </b-button> -->
          </div>
        </template>
      </b-table>
    </b-card>
  </div>
</template>
<script>
import {
  BCard,
  BRow,
  BCol,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BImg,
  VBTooltip,
  BFormCheckbox,
  BFormCheckboxGroup,
  BFormGroup,
  BForm,
  BContainer,
  BMediaAside,
  BMediaBody,
  BSpinner,
  BCardTitle,
  BCardText,
  BInputGroupPrepend,
  BFormInput,
  BInputGroup,
  BSidebar,
} from "bootstrap-vue";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import { mapActions, mapGetters } from "vuex";

import QrcodeVue from "qrcode.vue";
import { useClipboard } from "@vueuse/core";
const { copy } = useClipboard();

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BImg,
    BFormCheckbox,
    BFormCheckboxGroup,
    BFormGroup,
    BForm,
    BContainer,
    BMediaAside,
    BMediaBody,
    BSpinner,
    BCardTitle,
    BCardText,
    BInputGroupPrepend,
    BFormInput,
    BInputGroup,
    BSidebar,
    vSelect,
    flatPickr,
    QrcodeVue,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  data() {
    return {
      searchQuery: "",
      dataLoading: false,
      currentStatus: "",
      statusOptions: [
        { text: "All", value: "" },
        { text: "Pending", value: "pending" },
        { text: "Handled", value: "handled" },
        // { text: "Rejected", value: "rejected" },
      ],
      items: [],
      fields: [
        { label: "S.No", key: "index" },
        { label: "name", key: "name" },
        { label: "father name", key: "father_name" },
        { label: "contact", key: "contact_father" },
        { label: "enrolled on", key: "enrollmentDate" },
        { label: "status", key: "status" },
        "actions",
      ],
      reqObj: {},
      currentStd: {},
      reportDomain: this.$store.state.report.replace(/_/g, ""),
      qrValue: "",
    };
  },
  created() {
    this.LoadData();
  },
  computed: {
    filters: function () {
      return this.items.filter((pro) => {
        return (
          pro.st.name.toLowerCase().match(this.searchQuery.toLowerCase()) ||
          pro.st.grNo.toLowerCase().match(this.searchQuery.toLowerCase())
        );
      });
    },
  },
  methods: {
    ...mapActions({ get: "get", post: "post", put: "put", delete: "delete" }),

    openQr() {
      this.qrValue = `${window.location.origin}/student-enrollment?db=${this.$store.state.userData.db}&cID=${this.$store.state.userData.cId}`;
      this.$bvModal.show("modal-qr");
    },
    copyQr() {
      copy(this.qrValue);
      this.$bvToast.toast("Link Copied.", {
        title: "Copied!",
        variant: "success",
        toaster: "b-toaster-top-center",
        solid: true,
      });
    },
    saveQr() {
      const canvas = document.querySelector("#modal-qr canvas");
      let image = canvas.toDataURL();
      let dLink = document.createElement("a");
      dLink.download = "enrollment.png";
      dLink.href = image;
      dLink.click();
    },

    openReport(item, std) {
      // this.reqObj = { ...item };
      // this.currentStd = { ...std };
      this.changestatus(item);
      var url =
        `https://${this.reportDomain}.myskool.app/Certificate/Show?type=admission-form&dbb=` +
        this.$store.state.userData.db +
        "&cID=" +
        std.campusID +
        "&sID=" +
        std.id +
        "&cls=0&sec=0";
      window.open(url, "_blank");
    },
    async changestatus(item) {
      if (item.status == "pending") {
        item.status = "handled";
        item.approvedBy = this.$store.state.userData.userID;
        item.approvalDate = new Date().toJSON();

        var status = await this.put({
          url:
            this.$store.state.domain +
            "StudentOtherInfo/" +
            item.id +
            "?db=" +
            this.$store.state.userData.db,
          body: item,
          importing: true,
          context: this,
          token: this.$store.state.userData.token,
        });
        if (status) {
          this.LoadData();
        }
      }
    },

    async LoadData() {
      this.dataLoading = true;
      let myitem = await this.get({
        url:
          this.$store.state.domain +
          "StudentOtherInfo/LoadData?db=" +
          this.$store.state.userData.db +
          "&cID=" +
          this.$store.state.userData.cId +
          "&status=" +
          this.currentStatus,
        token: this.$store.state.userData.token,
      });
      // console.log(myitem);
      this.items = myitem;
      this.dataLoading = false;
    },
  },
};
</script>
<style scoped></style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
